import * as React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import TextWrapper from '../components/TextWrapper'
import Paragraph from '../components/Paragraph'
import Title from '../components/Title'

const EducationPage = () => (
  <Layout>
    <SEO title='Education' />
    <TextWrapper>
      <Title>D.E.A - 1980</Title>
      <Paragraph>
        Université de Nancy II, Faculté de Droit et des Sciences économiques,
        Nancy, France.
        <br />
        Economie d’intégration (thèse sur des aspects économiques de la Grèce).
      </Paragraph>
      <Title>Certificat des hautes études européennes - 1979</Title>
      <Paragraph>
        Collège d'Europe, Bruges, Belgium (Greek Government Scholarship).
        <br />
        Economics; European law.
      </Paragraph>
      <Title>Diplôme d’études supérieures européennes - 1978</Title>
      <Paragraph>
        Centre européen universitaire de Nancy, France (bourse de France).
        <br />
        Economie européenne.
      </Paragraph>
      <Title>Diploma in Economic Analysis - 1977</Title>
      <Paragraph>University of Kent at Canterbury, UK. Economics.</Paragraph>
      <Title>First degree (4-year) - 1971</Title>
      <Paragraph>
        University for Political Science, “Panteion”, Athens, Greece. Political
        science; public law; economics.
      </Paragraph>
    </TextWrapper>
  </Layout>
)

export default EducationPage
